footer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #333;
    text-align: center;
}
footer li {
    display: inline-block;
    margin-left: 16px;
}
footer li a{
    color: white;
}
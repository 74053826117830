#layout{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
body {
    background: rgb(244,244,244);
}
.main-container{
    margin: auto;
}
@media only screen and (max-width: 599px) {
    .main-container{
        width: 90%;
    }
}
@media only screen and (min-width: 600px) {
    .main-container{
        width: 80%;
    }
}
@media only screen and (min-width: 1000px) {
    .main-container{
        width: 70%;
    }
}
@media only screen and (min-width: 1400px) {
    .main-container{
        width: 60%;
    }
}
@media only screen and (min-width: 1800px) {
    .main-container{
        width: 50%;
    }
}
.page-header{
    font-family: "Helvetica";
    font-size: 3em;
}
#languages td{
    border-width: 0;
}
#languages img{
    width: auto;
    height: 2em;
}
.lang-icon{
    height: 32px;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
}
#lang-table{
    width: 200px;
}
#lang-table td{
    border: none;
}
.main-title{
    font-family: "Helvetica";
    font-size: 2em;
}
.article{
    font-family: "Arial", "Courier New", monospace;
    font-size: 1em;
    line-height: 2em;
}
/*Home*/
#h-buttons{
    position: sticky;
    top: 0;
    text-align: center;
}
#h-buttons a{
    display: inline-block;
    text-decoration: none;
    padding: 0.75em 1em 0.75em 1em;
    margin-top: 0em;
    margin-bottom: 1em;
    margin-left: 1em;
    border-radius: 0.25em;
    color: white;
}
#h-youtube{
    width: 100%;
}
@media only screen and (max-width: 1199px) {
    #h-youtube{
        height: 315px;
    }
    #h-buttons a{
        margin-right: 1em;
    }
    #h-gestures-img{
        width: 315px;
        height: 315px;
    }
    #h-quick-bar-img{
        width: 315px;
        height: 82px;
    }
    #h-mouse-img{
        width: 315px;
        height: 130px;
    }
}
@media only screen and (min-width: 1200px) {
    #h-gestures-img{
        width: 550px;
        height: 550px;
    }
    #h-quick-bar-img{
        width: 550px;
        height: 144px;
    }
    #h-mouse-img{
        width: 550px;
        height: 227px;
    }
    #h-youtube{
        height: 550px;
    }
    #h-buttons a{
        margin-right: 2em;
    }
}
#h-chrome{
    background-color: #ed0c0c;
}
#h-edge{
    background-color: #34afe3;
}
#h-firefox{
    background-color: #ff6200;
}
.h-img{
    margin: 2em;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
/*How to*/ 
.sub-title{
    font-weight: bold;
}
.h-t-img{
    border: 2px solid #555;
    margin:0.25em 0em 0.25em 0.25em;
}
#h-t-ring-img{
    float: right;
}
#h-t-custom-new-tab-img{
    float: right;
}
#h-t-pop-up-menu-img{
    float: right;
}
table{
    border-collapse: collapse;
}
td{
    border: 2px solid black;
    padding: 0.25em;
    margin: 0;
}
.arrow-icon{
    margin-bottom: 0;
    margin-top: 0;
    padding: 2px;
    width: 1em;
    height: 1em;
    border-width: 0;
    vertical-align: middle;
}
.icon{
    margin-bottom: 0;
    margin-top: 0;
    padding: 2px;
    width: 1em;
    height: 1em;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    vertical-align: middle;
}
#h-t-quick-nav{
    border-left: 2px solid #888;
    position: fixed;
    top:96px;
    float: right;
}
#h-t-quick-nav li{
    padding: 4px;
    color: dodgerblue;
}
#h-t-quick-nav li a:hover{
    background-color: #FFFFFF;
}
#h-t-quick-nav li a{
    text-decoration:none;
}
#h-t-quick-nav li a:visited{
    color: inherit;
}
@media only screen and (max-width: 1199px) {
    #h-t-quick-nav{
        visibility: hidden;
    }
}
@media only screen and (min-width: 1200px) {
    #h-t-quick-nav{
        right: 32px;
    }
}
@media only screen and (min-width: 1400px) {
    #h-t-quick-nav{
        right: 64px;
    }
}
@media only screen and (min-width: 1800px) {
    #h-t-quick-nav{
        right: 256px;
    }
}
.v-icon{
    width: 24px;
    height: 24px;
    border: 0;
    margin: 0;
    padding: 0;
}
#h-t-options-table td{
    text-align: center;
}
/*Go premium*/ 

/*Troubleshooting*/ 
.sub-title{
    font-weight: bold;
}
/*Contact*/ 
.container {
    border-radius: 8px;
    background-color: rgb(232, 190, 0);
    padding: 1em;
}
input[type=text],input[type=password], select, textarea, #email {
    width: 100%;
    padding: 12px;
    border: 1px solid black;
    border-radius: 4px; 
    box-sizing: border-box;
    margin-top: 6px; 
    margin-bottom: 16px; 
    resize: vertical
}
.issue{
    color:#ed0c0c;
}
label{
    font-weight: bold;
}
input[type=submit] {
    background-color: #333;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
input[type=submit]:hover {
    background-color: #888;
}
#c-content{
    height: 200px;
}
#sign-up-submit, #password-reset-submit{
    margin-left: 16px;
}
#login-submit{
    margin-left: 16px;
    margin-right: 16px;
}
/*premium*/
*, *:before, *:after {
    box-sizing: inherit;
}
.panel {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 8px 16px;
    position: relative;
    width: 100%;
}
.pricing-table {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
    display: flex;
    flex-direction: column;
    margin: 8px;
}
@media (min-width: 900px) {
    .pricing-table {
        flex-direction: row;
    }
}
.pricing-table * {
    text-align: center;
}
.pricing-plan {
    border-bottom: 1px solid #e1f1ff;
    padding: 1.5em;
}
.pricing-plan:last-child {
    border-bottom: none;
}
@media (min-width: 900px) {
    .pricing-plan {
        border-bottom: none;
        border-right: 1px solid #e1f1ff;
        flex-basis: 100%;
        padding: 1.5em;
    }
    .pricing-plan:last-child {
        border-right: none;
    }
}
.pricing-img {
    margin-bottom: 25px;
    max-width: 100%;
}
.pricing-header {
    color: #333;
    font-weight: 600;
    letter-spacing: 1px;
}
.pricing-features {
    color: #042e61;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 1.5em 0 1.5em;
}
.pricing-features-item {
    border-top: 1px solid #e1f1ff;
    font-size: 12px;
    line-height: 1.5;
    padding: 15px 0;
}
.pricing-features-item:last-child {
    border-bottom: 1px solid #e1f1ff;
}
.pricing-price {
    color: #042e61;
    display: block;
    font-size: 32px;
    font-weight: 700;
}
.pricing-button {
    border: 4px solid #042e61;
    border-radius: 10px;
    color: #042e61;
    display: inline-block;
    margin: 25px 0;
    padding: 15px 35px;
    text-decoration: none;
    transition: all 150ms ease-in-out;
}
.pricing-button:hover,
.pricing-button:focus {
    background-color: #e1f1ff;
}
.pricing-button.is-featured {
    background-color: #48aaff;
    color: #fff;
}
.pricing-button.is-featured:hover,
.pricing-button.is-featured:active {
    background-color: #269aff;
}

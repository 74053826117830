.active{
    background-color: #888;
}
li a:hover {
    background-color: #111;
}
#user {
    float:right;
}
#user-link{
    padding-top:0.5em;
    padding-bottom:0.5em;
}
#unloged{
    margin:0;
    height:32px;
    width:32px;
    background-color:#333;
    display: block;
    padding: 0;
    border: 0;
}
#nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #333;
    text-align: center;
}
#nav li {
    display: inline-block;
}
#nav li a {
    text-decoration: none;
    display: inline-block;
    color: white;
    padding: 1em 1em;
}
#logo-img{
    float:left;
    display: inline-block;
    padding-top: 1em;
    padding-bottom:  1em;
    padding-left: 0;
    width:1em;
    height: 1em;
    margin: 0;
    border: 0;
}
#logo-txt{
    color:white;
    float:left;
    display: inline-block;
    padding-top: 1em;
    padding-bottom:  1em;
    padding-left: 1em;
    padding-right: 0.5em;
}
/* user */
.dropbtn {
    background-color: #333;
    padding: 0.5em 1em 0.5em 1em;
    font-size: 1em;
    border: none;
}
/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #888;
    min-width: 8em;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.4);
    z-index: 1;
    left:auto;
    right:0;
    color: white;
}
#greeting {
    padding: 0.5em;
    text-decoration: none;
    display: block;
}
/* Links inside the dropdown */
.dropdown-content a {
    padding: 0.5em;
    text-decoration: none;
    display: block;
    text-align: start;
}
.dropdown-content button {
    border-width: 0;
    width: 100%;
    padding: 0.5em;
    text-decoration: none;
    display: block;
    text-align: start;
}
#loged{
    border: 0;
    display: block;
}
#log-out{
    padding: 0.5em;
    border: none;
    display: block;
    text-align: start;
    background-color: #888;
    color: white;
    width: 100%;
}
#log-out:hover{
    cursor: pointer;
    background-color: #111;
    width:100%;
    height: 100%;
}
/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}
/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #111;}
